.cards {
  display: grid;
  padding: 0;
  list-style: none;
  justify-content: flex-start;
  gap: var(--spacing-2);
  grid-template-columns: 1fr;

  @media (--tablet) {
    gap: var(--spacing-3);
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--tablet-l) {
    gap: var(--spacing-4);
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--desktop) {
    gap: var(--spacing-3);
  }

  & a {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
  }
}

.reviews {
  background: var(--color-championship-white);
  border: 1px solid var(--Colour-Neutral-100, #e6e6e6);

  @media (--tablet) {
    flex-grow: 1;

    & > a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.chipFilters {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
}

.makeTiles {
  padding: var(--spacing-10) 0;

  & h2 {
    margin: 0 0 var(--spacing-10);
  }
}

.browseBy {
  margin: var(--spacing-10) 0 var(--spacing-5);
}
