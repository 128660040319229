.labels {
  && {
    grid-row-start: feat-line;
    display: flex;
    flex-flow: row wrap;
    gap: var(--spacing-2);
    overflow: hidden;
    max-height: 28px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
}
