/*
* Colors
*/

/*
* Base
*/
.pill {
  font-size: 14px;
  padding: 2px 2px;
  display: inline-flex;
  vertical-align: middle;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: 0;
  min-height: 28px;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration: none;
  border-style: solid;
  background-color: var(--pill-background-color);
  color: var(--pill-text-color);

  &.vehicleSpec {
    border-radius: var(--spacing-1);
    padding: var(--spacing-1) var(--spacing-2);
    text-transform: capitalize;
  }
}

/*
* Icon
*/
.leftIcon {
  padding-right: var(--spacing-1);
  display: flex;
}
