.wrapper {
  padding: 0 var(--spacing-5) var(--spacing-10);
  margin-bottom: var(--spacing-10);
  overflow: hidden;
  margin: 0 auto;
  text-align: left;

  & > ul {
    margin: 0;
  }

  & h4 {
    margin: 0 0 var(--spacing-4);
  }

  @media (--tablet) {
    padding: 0 var(--spacing-6) var(--spacing-10);
  }
  @media (--tablet-l) {
    padding: 0 0 var(--spacing-10);
  }
}
